.RadioInput {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  input {
    display: none;
  }

  .RadioInput-circle {
    width: 31px;
    height: 31px;
    border: 1px solid var(--grey-shade-3);
    background-color: var(--white);
    border-radius: 50%;
    margin-right: 14px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  &:hover, &:focus {
    .RadioInput-circle {
      background-color: var(--blue-button);
    }
  }

  &.RadioInput-active {
    .RadioInput-circle {
      background-color: var(--blue-link);
      border-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      .RadioInput-outline-circle {
        border: 2px solid var(--white);
        width: 23px;
        height: 23px;
        box-sizing: border-box;
        border-radius: 50%;
      }
    }

    &:hover, &:focus {
      .RadioInput-circle {
        background-color: var(--blue-light);
      }
    }
  }

  &.RadioInput-error {
    .RadioInput-circle {
      border-color: var(--red);
    }
  }
}