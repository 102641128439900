.Footer {
  min-height: 212px;
  width: 100%;
  background-color: var(--blue-light);

  p, a {
    font-size: 14px;
    line-height: 24px;
    color: var(--white);
  }

  svg {
    fill: var(--white);
  }

  .button :last-child {
    margin-left: 8px;
  }

  .button {
    color: var(--white);
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "Arvo", serif;
    cursor: pointer;
    outline: none
  }

  a.button {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "Arvo", serif;
    text-decoration: none;

    svg {
      fill: var(--white);
    }
  }

  .button-container {
    padding-top: 54px;
    padding-bottom: 44px;
    display: flex;
    justify-content: center;

    svg {
      margin-right: 8px;
    }

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }

  .text-container {
    display: flex;
    align-items: center;
    flex-flow: column;
    // margin-bottom: 55px;
  }

  .row {
    display: flex;
  }
}
