.Modal {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2E667980;
  display: flex;
  justify-content: center;

  overflow-x: hidden;
  overflow-y: auto;

  .Modal-dialog {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    .Modal-content {
      margin-top: 80px;
      background-color: var(--white);
    }

    .Modal-margin {
      &::after {
        content: '';
        border: 1px solid var(--blue-dark);
        opacity: 0;
      }

      margin-top: 80px;
    }
  }
}
