.PrintContacts {
  padding: 16px;

  .PrintContacts-header {
    margin-bottom: 16px;
  }
}

.PrintContact {
  padding: 8px;
  break-inside: avoid;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border: 1px solid var(--grey-shade-2);
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
  }

  .PrintContact-info {
    display: flex;

    .PrintContact-info-left {
      flex: 4;

      h4 {
        margin-bottom: 10px;
      }
    }

    .PrintContact-info-right {
      flex: 5;

      .Role > p {
        max-width: 80%;
      }
    }
  }
}


@media print {
  .PrintContacts {
    padding: 0;

    .PrintContacts-header {
      display: none;
    }
  }
}
