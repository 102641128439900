.ConfirmSubmissionSuccess {
  background-color: var(--grey-dirty);
  min-height: var(--fullvh);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #logo {
    position: absolute;
    top: 50px;
  }

  .ConfirmSubmissionSuccess-box {
    background-color: var(--white);
    padding: 40px;
    width: 536px;

    p {
      margin-top: 14px;
    }
  }
}
