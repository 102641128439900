body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/

  &.modal-open {
    overflow-y: hidden;
    margin-right: var(--scroll-bar-width, 0);
  }
}

h1, h2, h3, h4, h5 {
  font-family: 'Arvo', serif;
  font-weight: 700;
  color: var(--blue-dark);
  margin: 0;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 37px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--blue-dark);
  margin: 0;
  font-size: 18px;
  line-height: 26px;
}

a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--blue-link);
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;

  svg {
    fill: var(--blue-link);
  }

  &:hover {
    color: var(--blue-dark);

    svg {
      fill: var(--blue-dark);
    }
  }
}

hr {
  background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, var(--blue-light) 0%);
  background-position: bottom;
  background-size: 12px 2px;
  background-repeat: repeat-x;
  border: none;
  height: 2px;
  margin: 24px 0 32px 0;
}

input {
  font-family: 'Montserrat', sans-serif;
  color: var(--blue-dark);
  font-size: 18px;
}

button {
  outline: none;
}

.modal-close-button:focus {
  svg {
    fill: var(--grey-shade-3)!important;
  }
}

:root {
  --fullvh: calc(var(--vh, 1vh) * 100);

  --blue-dark: #2E6679;
  --blue-link: #118AB2;
  --blue-light: #28ACD8;
  --blue-button: #EDF8F9;

  --shadow: #1E4D6529;

  --yellow: #FBB92D;
  --purple: #B40C7C;
  --red: #E7383F;
  --red-light: #FFEBEC;
  --green: #549013;
  --green-alt: #199F19;
  --grey-dirty: #F0EDE9;

  --white: #FFF;
  --grey-shade-1: #F5F5F5;
  --grey-shade-2: #E8E8E8;
  --grey-shade-3: #C3C3C3;
  --grey-shade-4: #969696;
  --grey-shade-5: #6A6A6A;
  --grey-shade-6: #313131;
}

@font-face {
  font-family: 'Arvo';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Arvo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
}

div, p, span, label {
  &.required {
    &::after {
      content: "*";
      margin-left: 6px;
      color: var(--red);
      font-size: 12px;
    }
  }
}