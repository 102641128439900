.Header {
  min-height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logout {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    > div {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin: 0 140px;
      max-width: 1088px;
      min-width: 895px;

      svg {
          margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: 933px) {
  .Header {
    .logout > div {
      max-width: 890px;
      min-width: 689px;
      margin: 0 22px;
    }
  }
}

@media screen and (max-width: 732px) {
  .Header {
    .logout > div {
      max-width: unset;
      min-width: unset;
      margin: 0 10px;
    }
  }
}
