.AdminTools {
  background-color: var(--grey-dirty);
  height: 100%;
  min-height: var(--fullvh);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;

  #logo {
    position: absolute;
    top: 50px;
  }

  .AdminTools-logout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > div {
      padding: 50px 130px;
      display: flex;
      align-items: center;
    }

    .AdminTools-version {
      font-size: 12px;
      margin-right: 12px;
      opacity: 0.7;
    }

    svg {
      margin-right: 8px;
    }
  }

  .AdminTools-body {
    display: flex;
    flex-flow: column;
    grid-gap: 16px;
  }

  .AdminTools-form {
    background-color: var(--white);
    padding: 37px 33px;
  }

  .AdminTools-exports {
    background-color: var(--white);
    padding: 37px 33px;
    min-width: 800px;

    .AdminTools-exports-subtitle {
      display: inline;
    }

    .table-export-download {
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      color: var(--blue-link);
      text-decoration: underline;

      &:hover {
        color: var(--blue-light);
      }
    }

    table {
      border: 1px solid var(--grey-shade-2);
      border-spacing: 0;
      min-width: 800px;
    }

    tbody {
      tr:nth-child(even) {
        background-color: var(--grey-shade-1);
      }
    }

    th {
      color: var(--blue-dark);
      font-family: 'Arvo', serif;
      text-align: left;
      height: 40px;
      padding: 0 8px;
      border-bottom: 1px solid var(--grey-shade-2);
      font-size: 16px;
    }

    td {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      padding: 0 8px;
      height: 40px;
      color: var(--blue-dark);
    }
  }

  .AdminTools-files {
    display: flex;
    flex-flow: column;

    .AdminTools-file-row {
      margin: 8px 0;
      display: flex;
      justify-content: space-between;

      label {
        margin-right: 16px;
        font-size: 18px;
        color: var(--blue-dark);
      }
    }
  }

  .AdminTools-input-row {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    margin-bottom: 26px;
  }

  .AdminTools-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .AdminTools-button-container {
      display: flex;
      flex-flow: column;

      h4 {
        margin-bottom: 26px;
      }

      .AdminTools-button svg {
        margin-right: 17px;
      }
    }
  }

  .AdminTools-reopen {
    background-color: var(--white);
    padding: 37px 33px;
    min-width: 800px;

    .AdminTools-reopen-input {
      width: 50%;
      margin-bottom: 16px;
    }
  }
}
