.Login {
  display: flex;
  min-height: var(--fullvh);
  height: 100%;

  .left {
    flex: 1 0 966px;
    background: radial-gradient(var(--blue-light), var(--blue-dark));
    display: flex;
    justify-content: center;
    align-items: center;

    .closedEnquete{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 70%;
    }

    .text {
      margin-right: 100px;
      width: 320px;

      small {
        font-size: 13px;
      }
    }

    h3 {
      display: inline;
    }

    h3, p {
      color: var(--grey-shade-1);
    }

    h3.yellow {
      color: var(--yellow);
      margin-bottom: 32px;
    }

    .image {
      display: flex;
      align-items: center;

      height: calc(100vh - 100px);

      img {
        height: 100%;
        max-height: 800px;
        min-height: 600px;
        //display: none;
      }
    }
  }

  .right {
    flex: 0 0 400px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .closedEnquete{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    img {
      margin-top: 56px;
    }

    h2 {
      margin: 56px 0 72px 0;
    }

    form {
      width: calc(100% - 56px - 56px);

      .Input-root:first-child {
        margin-bottom: 16px;
      }

      .submit {
        display: flex;
        justify-content: center;
      }

      button {
        margin: 32px 0;
      }
    }

    p {
      font-size: 12px;
      line-height: 20px;
      width: calc(100% - 56px - 56px);
      text-align: center;
      margin-bottom: 16px;

      a {
        font-size: 12px;
        line-height: 20px;
        display: inline;

        svg {
          margin-left: 6px;
        }
      }
    }

    .policy-button {
      flex: 1;
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;

      a {
        text-decoration: none;
      }
    }
  }
}
