.Home {
  height: 100%;
  min-height: var(--fullvh);
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: var(--grey-dirty);

  h2 {
    margin: 20px 0 20px;
  }

  h5 {
    margin: 0 0 40px;
  }

  .center {
    flex: 1;
    display: flex;
    flex-flow: column;
    margin: 0 140px 80px;
    max-width: 1088px;
    min-width: 895px;
  }

  .row-top {
    display: flex;
    margin-bottom: 16px;
  }

  .block {
    background-color: var(--white);

    &.school {
      padding: 36px 32px;
      flex: 1;
    }

    &.team {
      padding: 24px 32px 38px;
    }

    &.print {
      margin-left: 16px;
      padding: 18px 12px 12px;
      width: 260px;

      #printA {
        display: none;
      }
    }
  }

  .details {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 20px;
  }

  .details-container {
    .caption {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;

      &.with-button {
        display: flex;

        button {
          margin-left: 10px;
          padding: 0;
          background-color: transparent;
          border: none;
          color: var(--blue-link);
          cursor: pointer;
          font-family: "Montserrat", sand-serif;
          font-weight: 700;

          svg {
            fill: var(--blue-link);
          }

          &:hover {
            color: var(--blue-dark);

            svg {
              fill: var(--blue-dark);
            }
          }

          &:focus {
            color: var(--blue-light);

            svg {
              fill: var(--blue-light);
            }
          }
        }

        button {
          font-size: 12px;

          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .value {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
  }

  .school {
    .title {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
      }
    }
  }

  .print {
    display: flex;
    flex-flow: column;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 24px;
      }
    }

    .text {
      margin-top: 12px;
      flex: 1;
      background-color: var(--blue-button);
      padding: 16px;

      span, a {
        font-size: 16px;
        line-height: 24px;
      }

      svg {
        margin-left: 8px;
      }

      .acrobat-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
      }
    }
  }

  .team {
    .head {
      display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: flex-end;

        svg {
          margin-left: 8px;
        }

        p {
          margin-left: 12px;
          font-size: 14px;
          line-height: 24px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }

    .team-list {
      .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--blue-button);
        padding: 78px 0;

        p {
          opacity: 0.5;
          color: var(--blue-link);
          font-size: 16px;
          font-family: "Arvo", serif;
        }
      }

      .order {
        display: flex;
        align-items: center;
        background-color: var(--blue-button);
        padding: 24px;

        .text {
          margin: 0 16px;
          flex: 1;

          p {
            font-size: 18px;
          }

          p:last-child {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .Home-submit-button {
    display: flex;
    justify-content: center;
    margin-top: 28px;
  }
}

.Loading {
  height: 100vh;
  background: radial-gradient(var(--blue-light), var(--blue-dark));
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 24px;
  }
}

@media screen and (max-width: 933px) {
  .Home {
    .row-top {
      flex-flow: column;

      .print {
        margin-left: 0;
        margin-top: 16px;
        flex-flow: row;
        width: unset;
        height: 96px;
        padding: 12px;

        button {
          height: 100%;
          width: 153px;
          justify-content: flex-start;
          padding: 0 12px;

          span {
            width: 80px;
            text-align: left;
          }

          svg {
            margin-right: 18px;
          }
        }

        .text {
          display: flex;
          margin-top: 0;
          margin-left: 8px;
          align-items: center;

          .acrobat-logo {
            margin-right: 14px;
            margin-bottom: 0;
          }

          span, a {
            font-size: 16px;
            line-height: 24px;
            display: inline;
          }
        }
      }
    }

    .center {
      max-width: 890px;
      min-width: 689px;
      margin: 0 10px 80px;
    }
  }
}

@media screen and (max-width: 732px) {
  .Home {
    .center {
      max-width: 100vw;
      min-width: unset;
      margin: 0 0 80px;
    }

    .block.school {
      padding: 36px 0;
    }

    .block.team {
      padding: 24px 0 38px;
    }
  }
}
