.Input-root {
  display: flex;
  flex-flow: column;

  .Input-label {
    margin-bottom: 8px;
  }
  .Input-error {
    margin-top: 8px;
  }
}

.Input {
  height: 48px;
  box-sizing: border-box;
  border: 1px solid var(--grey-shade-3);
  background-color: var(--white);
  padding: 0 16px;
  outline: none;

  &:focus {
    border-color: var(--blue-link);
  }

  &.error {
    border-color: var(--red);
  }
}

.Input-label {
  font-family: 'Montserrat' sans-serif;
  font-size: 16px;
  color: var(--blue-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.required {
    justify-content: unset;
  }

  .Input-label-optional {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.6;
  }
}

.Input-error {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    margin-left: 8px;
    color: var(--red);
  }
}
