.Checkbox-root {
  .Input-error {
    margin-top: 8px;
  }
}

.Checkbox-wrapper {
  display: flex;
  outline: none;
  pointer-events: none;

  &.Checkbox-disabled {
    .Checkbox {
      background-color: var(--grey-shade-2);
    }

    label, .Checkbox {
      pointer-events: none;
    }
  }

  label, .Checkbox {
    pointer-events: initial;
  }

  &:hover, &:focus {
    .Checkbox {
      background-color: var(--blue-button);
      border-color: var(--blue-dark);

      &.checked {
        background-color: var(--blue-light);
      }
    }
  }

  .Checkbox {
    border: 1px solid var(--grey-shade-3);
    border-radius: 8px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    box-sizing: border-box;
    background-color: var(--white);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      display: none;
    }

    // &:hover, &.hover-alt {
    //   background-color: var(--blue-button);
    //   border-color: var(--blue-dark);
    // }

    &.error {
      border-color: var(--red);
    }

    &.checked {
      background-color: var(--blue-link);
      border: none;

      svg {
        display: initial;
      }
    }
  }

  .Checkbox-label {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: var(--blue-dark);
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }
}
