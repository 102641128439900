.PrivacyPolicy {
  height: 100%;
  min-height: var(--fullvh);
  background-color: var(--grey-dirty);
  display: flex;
  flex-flow: column;

  .center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 80px;
  }

  .content {
    margin-top: 40px;
    flex: 0 0 285px;
    min-width: 720px;
    background-color: var(--white);
    padding: 32px;
    position: relative;

    button {
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: -166px;
      color: var(--grey-shade-6);
      background-color: transparent;
      border: none;
      font-family: 'Arvo', serif;
      font-size: 16px;
      width: 136px;
      display: flex;

      svg {
        fill: var(--grey-shade-6);
        margin-right: 8px;
      }

      div {
        text-align: left;
      }
    }

    .text {
      > * {
        display: inline;
      }
    }
  }

  a {
    display: flex;
    align-items: center;

    svg {
      margin-left: 6px;
    }
  }
}

@media screen and (max-width: 732px) {
  .PrivacyPolicy {
    .content {
      min-width: unset;
      // max-width: unset;
      // min-width: unset;
      // margin: 0 10px;
      margin-left: 124px;

      button {
        left: -124px;
        width: 124px;
      }
    }
  }
}
