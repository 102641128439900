.Notifier {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 100;
}

.Notifier-card {
  background-color: var(--white);
  height: 89px;
  min-width: 355px;
  box-shadow: var(--shadow) 10px 15px;
  display: flex;
  align-items: center;
  padding-right: 36px;

  svg {
    margin: 0 20px 0 36px;
  }

  &:not(:last-child) {
    margin-bottom: 22px;
  }
}
