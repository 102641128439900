.SubmitModal {
  width: 536px;
  padding: 40px;

  .SubmitModal-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    .SubmitModal-close {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        fill: var(--grey-shade-6);
      }
    }
  }

  .SubmitModal-buttons {
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
