.Segment {
  background-color: var(--blue-button);
  padding: 32px 36px;
  margin-bottom: 24px;

  .Segment-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
  }

  .Segments-list {
    > * {
      margin-top: 16px;
    }
  }

  .Segment-years {
    display: flex;
    align-items: center;
    margin-left: 48px;

    > * {
      margin-right: 8px;
    }

    > p {
      font-size: 16px;
      lint-height: 19px;
      margin-right: 20px;
    }

    label {
      margin-left: 8px;
    }
  }

  .Segment-years-error {
    margin-left: 48px;
    display: flex;
    align-items: center;

    p {
      margin-left: 8px;
      font-size: 14px;
      color: var(--red);
    }
  }
}
