.CreateContactForm {
  width: 640px;
  padding: 40px;

  .CreateContactForm-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    .CreateContactForm-close {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        fill: var(--grey-shade-6);
      }
    }
  }

  .CreateContactForm-input {
    width: 290px;
  }

  .CreateContactForm-buttons {
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .CreateContactForm-title {
    margin-bottom: 18px;
  }

  .CreateContactForm-suggestions {
    display: flex;
    flex-flow: column;
  }

  .CreateContactForm-suggestion {
    &:not(:first-child) {
      margin-top: 30px;
    }

    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    .CreateContactForm-suggestion-info {
      box-sizing: border-box;
      width: 100%;
      min-height: 132px;
      border: 1px solid var(--grey-shade-2);
      margin-bottom: 8px;
      display: flex;
      padding: 26px 30px;

      .CreateContactForm-suggestion-info-left {
        flex: 4;

        h4 {
          margin-bottom: 10px;
        }
      }

      .CreateContactForm-suggestion-info-right {
        flex: 5;

        .Role > p {
          max-width: 80%;
        }
      }
    }
  }
}
