.EditForm {
  .EditForm-title{
    margin: 32px 0;
  }

  .EditForm-subtitle {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .EditForm-input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px 35px;
    margin-bottom: 32px;
  }

  .EditForm-checkboxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin-bottom: 32px;
  }

  .EditForm-segments {
    margin-bottom: 32px;
  }

  .EditForm-role-error {
    display: flex;
    align-items: center;

    p {
      margin-left: 8px;
      font-size: 14px;
      color: var(--red);
    }
  }

  .EditForm-buttons {
    display: flex;
    justify-content: flex-end;

    &.EditForm-buttons-loading {
      opacity: 0.7;
    }
  }

  .EditForm-radio-group {
    display: flex;
    margin-bottom: 32px;

    > :first-child {
      margin-right: 40px;
    }
  }

  .EditForm-confirm-surname {
    padding: 16px;
    max-width: 400px;

    h5 {
      margin-bottom: 20px;
    }

    .EditForm-confirm-surname-head {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      .EditForm-confirm-surname-close {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        cursor: pointer;

        svg {
          fill: var(--grey-shade-6);
        }
      }
    }
  }
}
