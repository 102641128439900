.Contact {
  display: flex;
  border: 1px solid var(--grey-shade-2);
  border-bottom: none;
  min-height: 133px;

  &:last-child {
    border-bottom: 1px solid var(--grey-shade-2);
  }

  &.Contact-error {
    background-color: var(--red-light);
    position: relative;

    .Contact-error-message {
      font-size: 12px;
      color: var(--red);
      position: absolute;
      bottom: 8px;
      left: 24px;
    }
  }

  .anchor-target {
    position: relative;
    top: -56px;
  }

  .info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-left: 24px;
    max-width: 334px;
    flex: 1;

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .roles {
    flex: 1;
    padding: 24px 0;
  }

  .buttons {
    display: flex;
    flex-flow: column;
    border-left: 1px solid var(--grey-shade-2);
    flex: 0 0 83px;

    button {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      background-color: transparent;
      border: none;
      font-family: "Montserrat", serif;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      padding: 0;
      cursor: pointer;
      position: relative;

      svg {
        margin-bottom: 6px;
      }

      &:hover, &:focus {
        &::after {
          z-index: 1;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      &.edit {
        color: var(--blue-link);

        &:hover, &:focus {
          background-color: var(--blue-button);

          &::after {
            border: 1px solid var(--blue-link);
          }
        }
      }

      &.delete {
        font-size: 9px;
        color: var(--red);
        border-top: 1px solid var(--grey-shade-2);
        box-sizing: border-box;

        &:hover, &:focus {
          background-color: var(--red-light);

          &::after {
            border: 1px solid var(--red);
          }
        }
      }

    }
  }
}

.Role {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  *:not(:last-child) {
    margin-right: 12px;
  }
}

.Role-Segment {
  padding: 0 8px;
  height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
  }
}

@media screen and (max-width: 933px) {
  .Contact .anchor-target {
    top: -86px;
  }
}
