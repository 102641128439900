.NumberInput {
  display: flex;
  border: 1px solid var(--grey-shade-3);
  height: 48px;
  width: 70px;
  box-sizing: border-box;

  input {
    min-width: 0;
    border: none;
    border-right: 1px solid var(--grey-shade-3);
    display: flex;
    align-items: center;
    padding-right: 8px;
    text-align: right;
    outline: none;
  }

  .buttons {
    display: flex;
    flex-flow: column;
    flex: 0 0 28px;

    button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: none;
      position: relative;
      height: 23px;
      padding: 0;

      &:hover, &:focus {
        background-color: var(--blue-button);

        svg {
          fill: var(--blue-dark);
        }

        &::after {
          z-index: 1;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid var(--blue-dark);
        }
      }

      &:last-child {
        border-top: 1px solid var(--grey-shade-3);

        &::after {
          height: 22px;
        }
      }

      &:disabled {
        background-color: var(--grey-shade-1);
        cursor: default;

        &::after {
          content: unset;
        }

        svg {
          fill: var(--grey-shade-3);
        }
      }
    }
  }
}
