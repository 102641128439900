.Button-root {
  * {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
  }

  &.default {
    button {
      position: relative;
      transition: all 0.04s ease-in-out;
      top: 0;
      left: 0;

      background-color: var(--purple);
      box-shadow: var(--shadow) 5px 5px 0px;
      border: none;
      padding: 0 24px;
      height: 52px;
      cursor: pointer;
      color: var(--white);

      &:disabled {
        background-color: var(--grey-shade-2);
      }

      svg {
        fill: var(--white);
      }

      &:hover {
        box-shadow: var(--shadow) 10px 10px 0px;
        top: -5px;
        left: -5px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: -5px;
          top: 0;
          bottom: -5px;
        }
      }

      &:focus {
        background-image: linear-gradient(#00000060, #00000060);
      }

      &:active {
        transition: none;
        box-shadow: none;
        top: 5px;
        left: 5px;
      }

      &:disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  &.ghost {
    button {
      background-color: transparent;
      border: none;
      padding: 0 16px;
      height: 38px;
      outline: none;
      color: var(--blue-link);
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        fill: var(--blue-link);
      }

      &:hover {
        color: var(--blue-dark);

        svg {
          fill: var(--blue-dark);
        }
      }

      &:focus {
        color: var(--blue-light);

        svg {
          fill: var(--blue-light);
        }
      }

      &:disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
