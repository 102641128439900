.Home .sticky {
  height: 52px;
  margin-bottom: 12px;
  margin-top: -18px;

  .sticky-center {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .Nav {
    margin: 0 140px;
    max-width: calc(1088px - 32px - 32px);
    min-width: 895px;
    flex: 1;
    height: 72px;
    padding: 0 32px;
  }
}

@media screen and (max-width: 933px) {
  .Home .sticky {
    height: 78px;

    .Nav {
      max-width: calc(890px - 10px - 10px);
      min-width: 689px;
      margin: 0 10px;
      height: 94px;
    }
  }
}

.Home .Nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-top: -18px;

  .buttons {
    max-width: 860px;
    margin-right: 12px;
    flex: 1;
    display: flex;
    background-color: var(--white);

    .large {
      width: 40px;

      svg {
        margin-bottom: 2px;
      }

      .nav-button {
        flex: unset;

        button {
          height: 39px;
          flex-flow: column;
          font-family: 'Montserrat', serif;
          font-size: 8px;
        }
      }
    }

    svg {
      fill: var(--blue-link);
    }

    .alphabet {
      display: flex;
      height: 40px;
      max-width: 780px;
      min-width: 600px;
      flex: 1;
    }

    .nav-button {
      display: flex;
      position: relative;
      flex: 1;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      text-decoration: none;
      cursor: default;

      &::after {
        z-index: 1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid var(--grey-shade-2);
        pointer-events: none;
      }

      button {
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
        border: none;
        color: var(--blue-link);
        font-family: 'Arvo', sans-serif;
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        position: relative;
        transition: all 0.1s ease-in-out;
        top: 1px;
        left: 1px;

        &::after {
          z-index: 1;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid transparent;
          transition: all 0.1s ease-in-out;
        }

        &:hover {
          background-color: var(--blue-button);
          top: -2px;
          left: -2px;

          &::after {
            z-index: 2;
            border-color: var(--blue-link);
          }

          &::before {
            content: "";
            position: absolute;
            left: -1px;
            right: -4px;
            top: -1px;
            bottom: -4px;
          }
        }

        &:active {
          transition-duration: 0.05s;
          top: 0;
          left: 0;
          background-color: var(--blue-link);
          color: var(--white);

          svg {
            fill: var(--white);
          }
        }

        &:disabled {
          background-color: var(--grey-shade-1);
          color: var(--grey-shade-3);
          pointer-events: none;
        }
      }
    }
  }

  .add-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    padding: 0;

    svg {
      width: 20px;
      margin-right: 12px;
    }

    p {
      width: 80px;
      font-size: 14px;
      color: var(--white);
      line-height: 18px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 933px) {
  .Home .Nav {
    .add-button button {
      height: 77px;
      width: 155px;
    }

    .buttons {
      margin-right: 6px;
      max-width: 664px;

      .large .nav-button {
        button {
          height: 77px;
        }
      }

      .alphabet {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        max-width: 584px;
        min-width: 376px;
        height: unset;

        button {
          height: 38px;
        }
      }
    }
  }
}

@media screen and (max-width: 732px) {
  .Home .Nav .buttons {
    max-width: unset;

    .alphabet {
      min-width: unset;
    }
  }
}
