.DateInput-root {
  .DateInput {
    height: 48px;
    width: 142px;
    box-sizing: border-box;
    border: 1px solid var(--grey-shade-3);
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      right: 10px;
    }

    &:hover {
      background-color: var(--blue-button);
      border-color: var(--blue-dark);
    }
  }
}
