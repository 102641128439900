.AdminLogin {
  background-color: var(--grey-dirty);
  height: 100%;
  min-height: var(--fullvh);
  display: flex;
  justify-content: center;
  align-items: center;

  #logo {
    position: absolute;
    top: 50px;
  }

  .AdminLogin-form {
    width: calc(400px - 52px - 52px);
    background-color: var(--white);
    padding: 28px 54px 52px;
    position: relative;
    display: flex;
    flex-flow: column;

    #logo {
      position: unset;
      top: unset;
      margin-bottom: 45px;
      align-self: center;
    }

    .AdminLogin-title {
      text-align: center;
      margin-bottom: 36px;
    }

    .Input-root {
      margin-bottom: 16px;
    }

    .AdminLogin-button {
      margin-top: 16px;
      align-self: center;
    }
  }
}
